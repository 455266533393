<template lang="pug">
AppCard(v-if="!formSent")
  form.pb-8(@submit.prevent="submitForm")
    AppFormInput(type="password" :label="$t('changePass.current')" name="current_password" required)
    AppFormInput(type="password" ref="input1" :label="$t('changePass.new')" name="new_password" @input="pass1 = $event.target.value" required)
    AppFormInput(type="password" ref="input2" :label="$t('changePass.confirm')" name="confirm_password"  @input="pass2 = $event.target.value" required)
    AppButton(:loading="loading" :label="$t('common.send')")
AppAlertCard(:title="$t('changePass.success_title')" :subtitle="$t('changePass.success_subtitle')" v-else)
</template>

<script>
import { computed, watch, onMounted } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
import { useGtag } from "vue-gtag-next";

import AppFormInput from "@/components/Form/AppFormInput";
import AppAlertCard from "@/components/AppAlertCard";
import AppButton from "@/components/AppButton";
import AppCard from "@/components/AppCard";
import { axios } from "@/utils/axios";
import { store } from "@/store";
import { encrypt } from "@/utils/crypto";

export default {
  components: { AppFormInput, AppCard, AppButton, AppAlertCard },
  setup() {
    const { event } = useGtag();

    const formSent = ref(false);
    const pass1 = ref("");
    const pass2 = ref("");
    const input1 = ref(null);
    const input2 = ref(null);

    const loading = ref(false);

    const isValid = computed(() => {
      return pass1.value === pass2.value;
    });

    const submitForm = async (e) => {
      try {
        loading.value = true;
        const formDataObject = {};
        const formData = new FormData();
        const body = new FormData(e.target);

        body.forEach((value, key) => (formDataObject[key] = value));

        formDataObject.current_password = encrypt(
          formDataObject.current_password
        );
        formDataObject.new_password = encrypt(formDataObject.new_password);
        formDataObject.confirm_password = encrypt(
          formDataObject.confirm_password
        );

        for (var key in formDataObject) {
          formData.append(key, formDataObject[key]);
        }

        await axios.post("contact/change_password", formData);

        event("password_changed", {
          event_category: "password_changed",
          event_label: "success",
        });

        formSent.value = true;
      } catch (error) {
        event("password_changed", {
          event_category: "password_changed",
          event_label: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    watch(isValid, (isValid) => {
      let i1 = input1.value.$refs.refInput.textInput;
      let i2 = input2.value.$refs.refInput.textInput;
      i1.setCustomValidity(isValid ? "" : "hey");
      i2.setCustomValidity(isValid ? "" : "hey");
    });

    onMounted(async () => {
      store.APP_LOADING = false;
    });

    return {
      isValid,
      input1,
      input2,
      pass2,
      submitForm,
      loading,
      formSent,
      pass1,
    };
  },
};
</script>
